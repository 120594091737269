import erResponseService from '../../../services/provider/erresponse'
import _ from 'lodash'
import { RECALL_STATUS, RECALL_STATUS_PROVIDER, RECALL_CLASSIFICATIONS,ER_RECALL_STATUS ,AFFECTED_PRODUCT_STATUS,OUTPUT_DATE_FORMAT} from '../../../misc/commons'
import SupplierModal from './SupplierModal'
import ResponseModal from './ResponseModal'
import ViewModal from '../../../components/part/shared/PreviewViewModal'
import {Er_Response_AgGrid} from '../../../misc/tableHeaders'
import { AG_GridCustomizedTableHeaders_Key} from '../../../misc/tableHeaders'
import { EMAIL_STATUS,ACTION_TYPE } from '../../../misc/commons'
export default {
    components: {
        SupplierModal,
        ResponseModal,
        ViewModal
    },
    data() {
        return {
            erStatus: EMAIL_STATUS,
            actionType: ACTION_TYPE,
            OUTPUT_DATE_FORMAT: OUTPUT_DATE_FORMAT,
            delay: 700,
            clicks: 0,
            timer: null,
            total: 0,
            items: [],
            supplierModalKey: 0,
            respModalKey: 0,
            viewModalKey: 0,
            listSupplier: [],
            listSupplierEmail: [],
            listUserName: [],
            roleName: '',
            count: 0,
            organizationId: null,
            userId: 0,
            LoggedInuserId: 0,
            search: {
                response_status: null,
                supplier_name: null,
                recallid: null,
                recallname: null,
                recall_sites: null,
                notification_date: null,
                response_date: null,
                username: null,
                disposal_method: null
            },
            columnDefs: null,
            gridOptions: {
                onFilterChanged: this.onFilterChanged,
                onSortChanged: this.onSortChanged,
            },
            table_Connection_Data: this.$store.state.CustomizedHeaders.filter(s => s.userId == this.$store.state.user.id)[0],
            gridApi: null,
            columnApi: null,
            item: {},
            user_Id: null,
            rowModelType: 'serverSide',
            searchQuery: { pageNumber: 1, pageSize: 50, sort: null, colId: null },
            listCategories: [],
            recall_status: _.cloneDeep(RECALL_STATUS),
            er_recall_status: _.cloneDeep(ER_RECALL_STATUS),
            recall_status_provider: RECALL_STATUS_PROVIDER,
            recall_classification: RECALL_CLASSIFICATIONS,
            affected_product_status: AFFECTED_PRODUCT_STATUS,
            listSites: []
        }
    },
    created(){
        this.roleName = this.$store.state.roleName
        this.rowModelType = 'serverSide'
        if (this._checkPermissions(['Provider_ResponderRole'])) {
            if (this.$store.state.lastedRouteNotAuth.fullPath != undefined) {
                return this.$router.push({ path: this.$store.state.lastedRouteNotAuth.fullPath })
            }
        }
        this.user_Id = this.$store.state.user.id
    },
    computed: {
        GetUserId()
        {
            return this.$store.state.user.id
        },
    },
    beforeMount() {
        this.columnDefs = Er_Response_AgGrid
    },
    mounted() {
        this.findSupplier()
        this.findUserNameByOrgId()
        this.fetchRecallSites()
    },
    methods: {
        saveState(params) {
            this.table_Connection_Data.Provider_Login.Er_Response_Headers = params.columnApi.getColumnState()
            localStorage.setItem(AG_GridCustomizedTableHeaders_Key, JSON.stringify(this.$store.state.CustomizedHeaders))
        },
        applyState(params) {
            var tableState = JSON.parse(localStorage.getItem(AG_GridCustomizedTableHeaders_Key))?.filter(s => s.userId == this.$store.state.user.id)[0]
            var columnState = tableState?.Provider_Login?.Er_Response_Headers
            if (columnState) {
                params.columnApi.applyColumnState({ state: columnState, applyOrder: true })
            }
        },
        onFilterChanged(event) {
            this.table_Connection_Data.Provider_Login.Er_Response_Filters=event.api.getFilterModel()
            localStorage.setItem(AG_GridCustomizedTableHeaders_Key, JSON.stringify(this.$store.state.CustomizedHeaders))
            this.saveState(event)
            this.fetchData(event)
        },
        setFilterModel(params) {
            var tableState = JSON.parse(localStorage.getItem(AG_GridCustomizedTableHeaders_Key))?.filter(s => s.userId == this.$store.state.user.id)[0]
            var filterModel = tableState?.Provider_Login?.Er_Response_Filters
            if (filterModel) {
                params.api.setFilterModel(filterModel)
            }
        },
        onSortChanged(event) {
            this.saveState(event)
            this.fetchData(event)
        },
        doubleClick(event){
            this.clicks++
            if(this.clicks === 1) {
                var self = this
                this.timer = setTimeout(function() {
                    self.clicks = 0
                }, this.delay)
            } else{
                clearTimeout(this.timer)
                this.viewResponseRecall(event)
                this.clicks = 0
            }
        },
        onCompleted(item) {
            this.findSupplier()
            this.findUserNameByOrgId()
            this.fetchData({ api: this.gridApi, columnApi: this.columnApi })
            if (item.mode == this.actionType.UPDATE) {
                this._showToast('Updated successfully!')
                if(item.status == 'Bounce')
                {
                    this.RespondClick(item)
                }
                else
                {
                    this.checkForRespondClick(item)
                }
            }
            else if (item.mode == this.actionType.INSERT) {
                this.checkForRespondClick(item)
            }
            if (item.mode == this.actionType.RESPOND) {
                this._showToast('Response sent successfully!')
            }
        },
        supplierChange() {
            this.fetchData()
        },
        findSupplier() {
            erResponseService.findMeta().then(resp => {
                this.listSupplier = [
                    ...resp.data.d
                ]
            })
        },
        fetchRecallSites(){
            erResponseService.fetchRecallSites().then(resp => {
                this.listSites = resp.data.d
            })
        },
        findUserNameByOrgId() {
            erResponseService.findUserName(this.$store.state.orgId).then(resp => {
                this.listUserName = [
                    ...resp.data.d
                ]
            })

        },

        fetchDelay() {
            this.$refs.table.fetchDelay()
        },
        addMoreClick() {
            this.supplierModalKey += 1
            this.$nextTick(() => {
                this.$refs.supplierModal.forceRerenderInsert(this.items)
            })
        },
        ViewClick(val) {
            var itemVal = null
            if(val.files != null || val.files !== 'undefined')
            {
                itemVal = {
                    extension: val.extension,
                    filename: val.filename,
                    signed_url: val.signed_url,
                    signed_url_review: val.signed_url_review,
                    fileSize: val.fileSize,
                    mimetype: val.mimetype
                }
            }
            else if(val.notificationfiles != null || val.notificationfiles !== 'undefined')
            {
                itemVal = {
                    extension: val.extension,
                    filename: val.filename,
                    signed_url: val.signed_url,
                    signed_url_review: val.signed_url_review,
                    fileSize: val.fileSize,
                    mimetype: val.mimetype
                }
            }
            this.viewModalKey += 1
            this.$nextTick(() => {
                this.$refs.viewModal.forceRerenderInsert(itemVal)
            })
        },

        fetchData(params) {
            this.applyState(params)
            this.setFilterModel(params)
            let that = this
            that.gridApi = params.api
            that.columnApi = params.columnApi
            //params.api.sizeColumnsToFit()
            const datasource = {
                getRows(params) {
                    that.searchQuery.colId = params.request?.sortModel[0]?.colId
                    that.searchQuery.sort = params.request?.sortModel[0]?.sort
                    that.search.response_status = params.request?.filterModel?.response_status?.filter
                    that.search.supplier_name = params.request?.filterModel?.supplier_name?.filter
                    that.search.recallid = params.request?.filterModel?.recallid?.filter
                    that.search.recallname = params.request?.filterModel?.recallname?.filter
                    that.search.recall_sites = params.request?.filterModel?.recall_sites?.filter
                    that.search.notification_date = params.request?.filterModel?.notification_date?.notificationDateFrom?.split(' ')[0]
                    that.search.response_date = params.request?.filterModel?.response_date?.responseDateFrom?.split(' ')[0]
                    that.search.username = params.request?.filterModel?.username?.filter
                    that.search.disposal_method = params.request?.filterModel?.disposal_method?.filter
                    that.search.filterModel = that.gridApi.getFilterModel()
                    erResponseService.findErResponse({
                        ...that.searchQuery,
                        sortField: params.request?.sortModel[0]?.colId,
                        sortDirection: params.request?.sortModel[0]?.sort,
                        ...that.search
                    })
                        .then(resp => {
                            if (!resp.error) {
                                const recallsarray = []
                                resp.data.d.c.forEach(x => {
                                    x.loggedInUserId = that.user_Id
                                })
                                for (var i = 0; i < resp.data.d.c.length; i++) {

                                    if (resp.data.d.c[i].document == null || resp.data.d.c[i].document == 'undefined' ) {
                                        resp.data.d.c[i].document = null
                                    }
                                    if(resp.data.d.c[i].distributorName === 'undefined')
                                    {
                                        resp.data.d.c[i].distributorName = null
                                    }
                                    if(resp.data.d.c[i].disposal_method === 'BLANK')
                                    {
                                        resp.data.d.c[i].disposal_method = ''
                                    }
                                    recallsarray.push(resp.data.d.c[i])
                                }
                                that.item = resp.data.d.c
                                that.total = resp.data.d.t
                                //return resp.data.d.c
                                if(that.total == 0){
                                    that.gridApi.showNoRowsOverlay()
                                }
                                else{
                                    that.gridApi.hideOverlay()
                                }
                                return recallsarray
                            }
                        }).then(response => {

                            params.successCallback(response)
                        })
                        .catch(error => {
                            console.error(error)
                            params.failCallback()
                        })
                }
            }
            // register datasource with the grid
            params.api.setServerSideDatasource(datasource)
        },
        onCellClicked(params) {
            if (params.colDef.field == 'response_status') {
                this.addMoreRespClick(params.data,'btn',params.colDef.field)
                return
            }
            if (params.column.colId === 'document') {
                if(params.event.srcElement.localName == 'img'){
                    return
                }
            }
            if(params.colDef.field === 'files')
            {
                if(params.event.target.dataset.action != undefined)
                {
                    if(params.event.target.dataset.action.includes('viewclick'))
                    {

                        for(let i=0 ;i< params.data.files.length;i++)
                        {
                            if (params.event.target.dataset.action === 'viewclick_' + params.data.files[i].responder_document) {
                                return this.ViewClick(params.data.files[i])
                            }
                        }
                    }
                }

            }
            if(params.colDef.field === 'notificationfiles')
            {
                if(params.event.target.dataset.action != undefined)
                {
                    if(params.event.target.dataset.action.includes('viewclick'))
                    {
                        for(let i=0 ;i< params.data.notificationfiles.length;i++)
                        {
                            if (params.event.target.dataset.action === 'viewclick_' + params.data.notificationfiles[i].respondernotificationdocId) {
                                return this.ViewClick(params.data.notificationfiles[i])
                            }
                        }
                    }
                }
            }
            if (params.column.colId === 'actions') {
                if (params.event.target.dataset.action === 'edit') {
                    this.EditClick(params.data)
                }
                if (params.event.target.dataset.action === 'delete') {
                    this.deleteByGUID(params.data)
                }
                if(params.event.target.dataset.action === 'view')
                {
                    this.viewResponseRecall(params.data)
                }
                if(params.event.target.dataset.action === 'respond')
                {
                    this.addMoreRespClick(params.data,'btn', params.column.colId)
                }
                return
            }
            else
            {
                this.viewResponseRecall(params.data)
            }
        },
        fetchDataOnSuccess(val,mode,item,action) {
            this.fetchData({ api: this.gridApi, columnApi: this.columnApi })
            this.findSupplier()
            this.findUserNameByOrgId()

            if (val != null && val != undefined && val != '') {
                this._showToast(val, { variant: 'success' })
            }
            if (mode != null && mode != undefined && mode != '') {
                this.RespClick(item,action)
            }
        },
        checkForRespondClick(val) {
            val.status = val.status == undefined ? val.response_status: val.status
            if(val.status == 'Bounce')
            {
                val.status = 'ERROR'
            }
            if (val.files== undefined) {
                val.files = val.Files
            }
            if (val.supplier_email== undefined) {
                val.supplier_email = val.response_email
            }
            if(val.FileLen > 0 && val.supplier_email != '' && val.supplier_email != null ){
                this.respModalKey += 1
                this.$nextTick(() => {
                    this.$refs.respModal.forceRerenderInsert(val)
                })
            }
        },
        RespondClick(val) {
            val.status = val.status == undefined ? val.response_status: val.status
            if(val.status == 'Bounce')
            {
                val.status = 'DRAFT'
            }
            if (val.files== undefined) {
                val.files = val.Files
            }
            if (val.supplier_email== undefined) {
                val.supplier_email = val.response_email
            }
            if(val.FileLen > 0 && val.supplier_email != '' && val.supplier_email != null ){
                this.respModalKey += 1
                this.$nextTick(() => {
                    this.$refs.respModal.forceRerenderInsert(val)
                })
            }
        },
        addMoreRespClick(val,mode,cellClicked) {
            val.status = val.status == undefined ? val.response_status: val.status
            if (val.files== undefined) {
                val.files = val.Files
            }
            if (val.supplier_email== undefined) {
                val.supplier_email = val.response_email
            }
            if(val.files.length > 0 && val.supplier_email != '' && val.supplier_email != null ){
                if (mode == 'btn'){
                    let isFile = true
                    val.files.forEach((x, i) => {
                        if(x.extension == null)
                        {
                            isFile = false
                        }
                    })
                    if(!isFile){
                        if(val.status == this.erStatus.SENT)
                        {
                            this.respModalKey += 1
                            this.$nextTick(() => {
                                this.$refs.respModal.forceRerenderInsert(val)
                            })
                        }
                        else
                        {
                            this.supplierModalKey += 1
                            this.$nextTick(() => {
                                this.$refs.supplierModal.forceRerenderUpdate(val, this.items,'RES_UPDATE')
                            })
                        }

                    }
                    else
                    {
                        if(val.status == this.erStatus.ERROR){
                            if(cellClicked == 'response_status')
                            {
                                this.respModalKey += 1
                                this.$nextTick(() => {
                                    this.$refs.respModal.forceRerenderInsert(val)
                                })
                            }
                            else
                            {
                                this.supplierModalKey += 1
                                this.$nextTick(() => {
                                    this.$refs.supplierModal.forceRerenderUpdate(val, this.items,'RES_UPDATE')
                                })
                            }
                        }
                        else
                        {
                            this.respModalKey += 1
                            this.$nextTick(() => {
                                this.$refs.respModal.forceRerenderInsert(val)
                            })
                        }
                    }
                }
                else if(mode == 'opt')
                {
                    if(val.status == this.erStatus.ERROR)
                    {
                        this.supplierModalKey += 1
                        this.$nextTick(() => {
                            this.$refs.supplierModal.forceRerenderUpdate(val, this.items,'RES_UPDATE')
                        })
                    }
                    else if(val.status == undefined)
                    {
                        let isFile = true
                        val.files.forEach((x, i) => {
                            if(x.extension == null)
                            {
                                isFile = false
                            }
                            else
                            {
                                this.respModalKey += 1
                                this.$nextTick(() => {
                                    this.$refs.respModal.forceRerenderInsert(val)
                                })
                            }
                            if(!isFile){
                                this.supplierModalKey += 1
                                this.$nextTick(() => {
                                    this.$refs.supplierModal.forceRerenderUpdate(val, this.items,'RES_UPDATE')
                                })
                            }
                        })
                    }
                    else
                    {
                        var isFile = true
                        val.files.forEach((x, i) => {
                            if(x.extension == null || x.file == null)
                            {
                                isFile = false
                            }
                            else
                            {
                                this.respModalKey += 1
                                this.$nextTick(() => {
                                    this.$refs.respModal.forceRerenderInsert(val)
                                })
                            }
                            if(!isFile){
                                this.supplierModalKey += 1
                                this.$nextTick(() => {
                                    this.$refs.supplierModal.forceRerenderUpdate(val, this.items,'RES_UPDATE')
                                })
                            }
                        })
                    }
                }
            }
            else
            {
                if(val.status == undefined)
                {
                    let isFile = true
                    if(val.files.length == 0)
                    {
                        this.supplierModalKey += 1
                        this.$nextTick(() => {
                            this.$refs.supplierModal.forceRerenderUpdate(val, this.items,'RES_UPDATE')
                        })
                    }
                    else
                    {
                        val.files.forEach((x, i) => {
                            if(x.extension == null)
                            {
                                isFile = false
                            }
                            else
                            {
                                this.respModalKey += 1
                                this.$nextTick(() => {
                                    this.$refs.respModal.forceRerenderInsert(val)
                                })
                            }
                            if(!isFile){
                                this.supplierModalKey += 1
                                this.$nextTick(() => {
                                    this.$refs.supplierModal.forceRerenderUpdate(val, this.items,'RES_UPDATE')
                                })
                            }
                        })
                    }

                }
                else if(val.status == this.erStatus.SENT)
                {
                    this.respModalKey += 1
                    this.$nextTick(() => {
                        this.$refs.respModal.forceRerenderInsert(val)
                    })
                }
                else if (mode == 'btn'){
                    if(val.status == this.erStatus.ERROR)
                    {
                        this.respModalKey += 1
                        this.$nextTick(() => {
                            this.$refs.respModal.forceRerenderInsert(val)
                        })
                    }
                    else if(val.status == this.erStatus.DRAFT)
                    {
                        this.supplierModalKey += 1
                        this.$nextTick(() => {
                            this.$refs.supplierModal.forceRerenderUpdate(val, this.items,'RES_UPDATE')
                        })
                    }
                    else
                    {
                        this.respModalKey += 1
                        this.$nextTick(() => {
                            this.$refs.respModal.forceRerenderInsert(val)
                        })
                    }
                }
                else
                {
                    this.supplierModalKey += 1
                    this.$nextTick(() => {
                        this.$refs.supplierModal.forceRerenderUpdate(val, this.item,'RES_UPDATE')
                    })
                }
            }
        },
        RespClick(val,action) {
            if(action == 'ERRORUPDATE' && val.status == this.erStatus.BOUNCE)
            {
                val.status = this.erStatus.DRAFT
            }
            if(val.FileLen > 0 && val.response_email != '' && val.response_email != null){
                this.respModalKey += 1
                this.$nextTick(() => {
                    this.$refs.respModal.forceRerenderInsert(val)
                })
            }
        },
        viewRecall(item) {
            this.$router.push({ name: 'er_Responses', params: { id: item.communicationId } })
        },
        editRecall(item, e) {
            this.$router.push({ name: 'prov_Recall_EditItem', params: { id: item.communicationId } })
            e.stopPropagation()
        },
        _checkRole(roleName) {
            if(this.$store.state.roleName==='Responder')
            {  return false}else{return true}

        },
        async download(dataurl) {
            this.ViewClick(dataurl)
        },
        getRecallByUId(communicationId) {
            this.EditClick(communicationId)
        },
        EditClick(val) {
            this.supplierModalKey += 1
            this.$nextTick(() => {
                this.$refs.supplierModal.forceRerenderUpdate(val, this.items,'UPDATE')
            })
        },
        deleteByGUID(val) {
            this._showConfirm('Are you sure do you want to delete the recall, this cannot be undone?', { title: 'Delete Recall', variant: 'danger' }).then(confirm => {
                if (confirm) {
                    erResponseService.deleteResponderRecall(val.responder_recallid).then(resp => {
                        this.fetchDataOnSuccess('Recall deleted successfully',null)
                        this.fetchData({ api: this.gridApi, columnApi: this.columnApi })
                    })
                }
            })
        },
        viewResponseRecall(val) {
            this.supplierModalKey += 1
            this.$nextTick(() => {
                this.$refs.supplierModal.forceRerenderUpdate(val, [],'VIEW')
            })
        }
    }
}